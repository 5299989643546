html {
  scroll-behavior: smooth;
}

body {
  color: #000;
}

.logo {
  height: 60px;
}

.custom-navbar {
  background-color: transparent !important;
  z-index: 9999999;
}

.bt {
  padding: 1rem;
}

.subtitle {
  font-size: 1.4rem;
  letter-spacing: 2px;
  font-weight: bold;
}

/* clode */

.scene {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: skyblue;
  background-attachment: fixed;
  overflow: hidden;
}

.moon {
  position: relative;
  top: 100px;
  left: 400px;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
}

.moon:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 15px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#111425, #3751e0);
  background-attachment: fixed;
}

.forest {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scale(1.2);
}

.scene i {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  animation: animate linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  10%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.cloud1 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 600px;
  animation: animateCloud 50s linear infinite;
}
.cloud2 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 500px;
  animation: animateCloud 35s linear infinite;
}
.cloud3 {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 400px;
  animation: animateCloud 80s linear infinite;
}
.cloud4 {
  position: absolute;
  top: 200px;
  left: 0;
  max-width: 300px;
  animation: animateCloud 70s linear infinite;
  animation-delay: -20s;
}
.cloud5 {
  position: absolute;
  top: 150px;
  left: 0;
  max-width: 500px;
  animation: animateCloud 40s linear infinite;
  animation-delay: -5s;
}
.cloud6 {
  position: absolute;
  top: 75px;
  left: 0;
  max-width: 400px;
  animation: animateCloud 30s linear infinite;
  animation-delay: -10s;
}

@keyframes animateCloud {
  0% {
    transform: translateX(-100%) scale(1);
  }
  100% {
    transform: translateX(400%) scale(1.5);
  }
}

/* cloude */

.showcase {
  z-index: 10000000 !important;
}

.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.roadmap {
  padding: 5rem 0;
  background-color: #feca69;
}

.cusbtn {
  background-color: white;
  color: #000;
  font-size: 1.4rem;
  padding: 0.5rem 1.5rem;
  border: 3px solid #000;
  font-weight: bold;
  border-radius: 20px;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #000;
  font-weight: bold;
  height: 70px;
  width: 70px;
}
.number {
  font-weight: bold;
  font-size: 3rem;
}

.si {
  height: 40px;
  margin: 0 10px;
}

.team {
  padding: 5rem 0;
  background: skyblue;
}

.team-img {
  border-radius: 10px;
  /* border: 1px solid #000; */
}

.text-wrapper {
  margin-top: 2rem;
}
.number-text {
  font-size: 2rem;
  font-weight: bold;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  /* font-size: 100px; */
}

.plus-img {
  height: 60px;
}

.faq {
  padding: 5rem 0;
}

.footer {
  padding: 2rem 0;
  background-color: #feca69;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 50px !important;
  }

  .scene {
    height: auto;
    padding-bottom: 2rem;
  }

  .mih1 {
    font-size: 1.5rem;
  }

  .rmt-1 {
    margin-top: 1rem !important;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
}

/* new css */
.mint {
  border: 3px solid #fff;
  padding: 4rem;
  border-radius: 1.5rem;
  background-color: #a6dff788;
  z-index: 9999999999;
  margin-top: 3rem;
}
